











































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import PointCard from "@/components/PointCard.vue";
import ContentPlaceholder from "@/components/ContentPlaceholder.vue";
import Loader from "@/components/Loader.vue";
import { CURRENT_YEAR } from "@/config";

const { mapState, mapActions } = createNamespacedHelpers("point");

export default Vue.extend({
  computed: {
    ...mapState([
      "points",
      "status",
      "perPage",
      "currentPage",
      "lastPage",
      "certificateURL",
      "progress",
      "fulfilled",
      "year"
    ]),
    loading(): string | boolean {
      return this.status.loading;
    },
    nextPage(): string | boolean {
      return this.currentPage < this.lastPage ? this.currentPage + 1 : null;
    },
    isCurrentYear(): boolean {
      return this.year === CURRENT_YEAR;
    }
  },
  methods: {
    ...mapActions(["fetchPoints"]),
    fetchNextPage() {
      this.fetchPoints(this.nextPage);
    }
  },
  components: {
    PointCard,
    Loader,
    ContentPlaceholder
  }
});
