
































import Vue from "vue";
import { EventType } from "@/types";
import { parseDate } from "@/helpers/DateHelper";
import { format } from "date-fns";

export default Vue.extend({
  props: {
    start: {
      type: Date as new () => Date
    },
    end: {
      type: Date as new () => Date
    },
    eventType: {
      type: String as () => EventType
    },
    fullView: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    year(): String {
      return format(this.start, "yyyy");
    },

    startDayMonth(): String {
      return format(this.start, "dd.MM");
    },

    endDayMonth(): String {
      return format(this.end, "dd.MM");
    },

    startTime(): String {
      return format(this.start, "HH:mm");
    },

    endTime(): String {
      return format(this.end, "HH:mm");
    }
  }
});
